var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c(
            "vs-tabs",
            { attrs: { color: _vm.colorx } },
            [
              _c("vs-tab", { attrs: { label: _vm.$t("plan.title") } }, [
                _c("div", { staticClass: "flex flex-col gap-4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:62",
                                expression: "'required|max:62'",
                              },
                            ],
                            staticClass: "w-full required",
                            attrs: {
                              id: "plan_name",
                              label: _vm.$t("plan.name"),
                            },
                            model: {
                              value: _vm.model.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "name", $$v)
                              },
                              expression: "model.name",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("plan_name"),
                                  expression: "errors.has('plan_name')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("plan_name")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:62",
                                expression: "'required|max:62'",
                              },
                            ],
                            staticClass: "w-full required",
                            attrs: {
                              id: "plan_internal_name",
                              label: _vm.$t("plan.internal_name"),
                            },
                            model: {
                              value: _vm.model.internal_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "internal_name", $$v)
                              },
                              expression: "model.internal_name",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("plan_internal_name"),
                                  expression:
                                    "errors.has('plan_internal_name')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.errors.first("plan_internal_name"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full grid grid-cols-1 sm:grid-cols-4 md:grid-cols-4 gap-2",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.$t("plan.visible_signature"))),
                          ]),
                          _c("vs-switch", {
                            model: {
                              value: _vm.model.visible_signature,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "visible_signature", $$v)
                              },
                              expression: "model.visible_signature",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("visible_signature"),
                                  expression: "errors.has('visible_signature')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.errors.first("visible_signature"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "vs-select",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass:
                                "w-full sm:w-1/2 flex-shrink required",
                              attrs: {
                                id: "plan_currency_type",
                                label: _vm.$t("plan.currency_type"),
                              },
                              model: {
                                value: _vm.model.currency_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "currency_type", $$v)
                                },
                                expression: "model.currency_type",
                              },
                            },
                            _vm._l(
                              _vm.currencyTypeList,
                              function (item, index) {
                                return _c("vs-select-item", {
                                  key: index,
                                  attrs: { value: item.value, text: item.text },
                                })
                              }
                            ),
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("plan_currency_type"),
                                  expression:
                                    "errors.has('plan_currency_type')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.errors.first("plan_currency_type"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|numeric",
                                expression: "'required|numeric'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              id: "plan_price_value",
                              lang: "pt-BR",
                              type: "number",
                              label: _vm.$t("plan.price_value"),
                            },
                            model: {
                              value: _vm.model.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "value", $$v)
                              },
                              expression: "model.value",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("plan_price_value"),
                                  expression: "errors.has('plan_price_value')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.errors.first("plan_price_value"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "numeric|max:3",
                                expression: "'numeric|max:3'",
                              },
                            ],
                            attrs: {
                              id: "plan_duration",
                              type: "number",
                              maxlength: "3",
                              label: _vm.$t("plan.duration_in_day"),
                            },
                            on: { keypress: _vm.isNumberInteger },
                            model: {
                              value: _vm.model.duration,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "duration", $$v)
                              },
                              expression: "model.duration",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("plan_duration"),
                                  expression: "errors.has('plan_duration')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("plan_duration")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "w-full mt-2" }, [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(_vm._s(_vm.$t("billing.billing_cycle"))),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "vs-radio",
                          {
                            staticClass: "mr-4",
                            attrs: {
                              "vs-name": "billing_cycle",
                              "vs-value": "MO",
                            },
                            model: {
                              value: _vm.model.billing_cycle,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "billing_cycle", $$v)
                              },
                              expression: "model.billing_cycle",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("billing.cycle.MO")))]
                        ),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "mr-4",
                            attrs: {
                              "vs-name": "billing_cycle",
                              "vs-value": "AN",
                            },
                            model: {
                              value: _vm.model.billing_cycle,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "billing_cycle", $$v)
                              },
                              expression: "model.billing_cycle",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("billing.cycle.AN")))]
                        ),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "mr-4",
                            attrs: {
                              "vs-name": "billing_cycle",
                              "vs-value": "SA",
                            },
                            model: {
                              value: _vm.model.billing_cycle,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "billing_cycle", $$v)
                              },
                              expression: "model.billing_cycle",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("billing.cycle.SA")))]
                        ),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "mr-4",
                            attrs: {
                              "vs-name": "billing_cycle",
                              "vs-value": "QA",
                            },
                            model: {
                              value: _vm.model.billing_cycle,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "billing_cycle", $$v)
                              },
                              expression: "model.billing_cycle",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("billing.cycle.QA")))]
                        ),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "mr-4",
                            attrs: {
                              "vs-name": "billing_cycle",
                              "vs-value": "SU",
                            },
                            model: {
                              value: _vm.model.billing_cycle,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "billing_cycle", $$v)
                              },
                              expression: "model.billing_cycle",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("billing.cycle.SU")))]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("billing_cycle"),
                                expression: "errors.has('billing_cycle')",
                              },
                            ],
                            staticClass: "text-danger text-sm",
                          },
                          [_vm._v(_vm._s(_vm.errors.first("billing_cycle")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          id: "plan_redirect_url",
                          name: "redirect_url",
                          label: _vm.$t("plan.redirect_url"),
                        },
                        model: {
                          value: _vm.model.redirect_url,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "redirect_url", $$v)
                          },
                          expression: "model.redirect_url",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("redirect_url"),
                              expression: "errors.has('redirect_url')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v(_vm._s(_vm.errors.first("redirect_url")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          id: "plan_terms_url",
                          name: "terms_url",
                          label: _vm.$t("plan.terms_url"),
                        },
                        model: {
                          value: _vm.model.terms_url,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "terms_url", $$v)
                          },
                          expression: "model.terms_url",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("terms_url"),
                              expression: "errors.has('terms_url')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v(_vm._s(_vm.errors.first("terms_url")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("label", { staticClass: "text-sm pl-1" }, [
                        _vm._v(_vm._s(_vm.$t("plan.description"))),
                      ]),
                      _c("complex-editor", {
                        attrs: { editor_data: _vm.model.description },
                        on: {
                          "update:editor_data": function ($event) {
                            return _vm.$set(_vm.model, "description", $event)
                          },
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("description"),
                              expression: "errors.has('description')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v(_vm._s(_vm.errors.first("description")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-full flex gap-2 justify-end" },
                    [
                      !_vm.isEdit()
                        ? _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "plans.create",
                                  expression: "'plans.create'",
                                },
                              ],
                              attrs: { disabled: !_vm.validateForm },
                              on: { click: _vm.createOrUpdate },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                          )
                        : _vm._e(),
                      _vm.isEdit()
                        ? _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "plans.edit",
                                  expression: "'plans.edit'",
                                },
                              ],
                              attrs: { disabled: !_vm.validateForm },
                              on: { click: _vm.createOrUpdate },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "vs-button",
                        {
                          attrs: { type: "border" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm.isEdit()
                ? _c("vs-tab", { attrs: { label: _vm.$t("plan.limits") } }, [
                    _c(
                      "div",
                      {
                        staticClass: "vx-row mb-6",
                        staticStyle: { "padding-top": "30px" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c("plan-period-limit", {
                              attrs: { plan_id: _vm.id },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.isEdit()
                ? _c(
                    "vs-tab",
                    { attrs: { label: _vm.$t("plan.integration") } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vx-row mb-6",
                          staticStyle: { "padding-top": "30px" },
                        },
                        [
                          _c("div", { staticClass: "vx-col w-full" }, [
                            _c(
                              "div",
                              { staticClass: "w-full" },
                              [
                                _c("PlanIntegration", {
                                  attrs: { model: _vm.modelIntegration },
                                  on: {
                                    "update:model": function ($event) {
                                      _vm.modelIntegration = $event
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-full flex gap-2 justify-end pt-4",
                              },
                              [
                                _vm.isEdit()
                                  ? _c(
                                      "vs-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "plans.edit",
                                            expression: "'plans.edit'",
                                          },
                                        ],
                                        attrs: {
                                          disabled:
                                            !_vm.validateIntegrationForm,
                                        },
                                        on: { click: _vm.saveIntegrationForm },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("action.save")) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "vs-button",
                                  {
                                    attrs: { type: "border" },
                                    on: { click: _vm.cancel },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("common.back")) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }