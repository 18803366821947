<template>
  <div class="w-full gap-2">
    <fieldset class="mt-4 border-faint-grey">
      <legend>{{ $t('plan.stripe_integration') }}</legend>
      <div class="w-full p-2">
        <vs-input
          id="plan_integration_name"
          class="w-full"
          :label="$t('plan.integration_name')"
          v-model="model.name"/>
      </div>
      <div class="w-full p-2">
        <vs-input
          id="plan_integration_foreign_id"
          class="w-full"
          :label="$t('plan.stripe_id')"
          v-model="model.foreign_id"/>
      </div>
      <div class="w-full p-2">
        <vs-input
          id="plan_integration_key"
          class="w-full"
          :label="$t('plan.stripe_key')"
          v-model="model.key"/>
      </div>
      <div class="w-full p-2">
        <vs-input
          id="plan_integration_secret"
          class="w-full"
          :label="$t('plan.stripe_secret')"
          v-model="model.secret"/>
      </div>
      <div class="w-full p-2">
        <vs-input
          id="plan_integration_subscription_id"
          class="w-full"
          :label="$t('plan.subscription_id')"
          v-model="model.subscription_id"/>
      </div>

    </fieldset>
  </div>
</template>

<script>


export default {
  directives: {

  },
  components: {

  },
  props: {
    model: {
      type: Object,
      default: {
        id: null,
        name: null,
        foreign_id: null,
        key: null,
        secret: null,
        subscription_id: null
      },
    }
  },

  data: () => ({

  }),
  computed: {

  },
  beforeMount() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style>
.vs-list--header{
  display: inline !important;
}
</style>
