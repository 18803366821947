<template>
  <div class="w-full gap-2">
    <div class="w-full pb-2"> <label class="block text-left font-medium">{{ $t('plan.period') }}</label><hr class="border-faint-grey"></div>
    <div class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
      <div>
        <label for="plan_period_finish">{{ $t('plan.start') }}</label>
        <div class="flex">
            <datetime
              id="plan_period_start"
              v-model="model.start_at"
              input-class="vs-inputx vs-input--input normal required"
              :phrases="{ok: 'Ok', cancel: 'Cancelar'}"
              type="date"
              format="dd/MM/yyyy"
              value-zone="America/Sao_Paulo"
              class="flex-auto w-8/12 mr-1 tour-config-4"
              :placeholder="$t('data')">
          </datetime>
          <span class="text-danger text-sm" v-show="errors.has('plan_period_start')">{{errors.first('plan_period_start')}}</span>
        </div>
      </div>
      <div>
        <label for="plan_period_finish">{{ $t('plan.finish') }}</label>
        <div class="flex">
          <datetime
              id="plan_period_finish"
              v-model="model.finish_at"
              input-class="vs-inputx vs-input--input normal required"
              :phrases="{ok: 'Ok', cancel: 'Cancelar'}"
              type="date"
              format="dd/MM/yyyy"
              value-zone="America/Sao_Paulo"
              class="flex-auto w-8/12 mr-1 tour-config-4"
              :placeholder="$t('data')">
          </datetime>
          <span class="text-danger text-sm" v-show="errors.has('plan_period_finish')">{{errors.first('plan_period_finish')}}</span>
        </div>
      </div>
    </div>
    <div class="w-full pb-2 pt-3"> <label class="block text-left font-medium">{{ $t('plan.limits') }}</label><hr class="border-faint-grey"></div>
    <div class="w-full gap-2 p-3">
        <vs-button
          @click="showPopupLimitType = true"
          icon="add"
          type="flat"
          class="float-right mb-3">
          {{ $t('adicionar') }}
        </vs-button>
        <vs-divider />
        <plan-limit-type-popup
          :visible="showPopupLimitType"
          @selected="addLimitTypesList"
          @close="closePopup"/>
    </div>
    <div class="grid grid-cols-1 gap-y-1">
      <div v-for="(data, index) in limitsList" :key="`cIndex_${index}`">
        <plan-limit :model="data"
                    :index="index"
                    @deleteModel="deleteLimitInAdd"></plan-limit>
      </div>
    </div>
    <div class="w-full flex gap-2 justify-end pt-6">
      <vs-button
        :disabled="!validateForm"
        v-permission="'plans.edit'"
        @click="savePeriodLimits">
        {{ $t('action.save') }}
      </vs-button>
      <vs-button
        type="border"
        @click="cancel">
        {{ $t('common.back') }}
      </vs-button>
    </div>
    <vs-divider class="mt-4">{{ $t('plan.period_limit_add') }}</vs-divider>
    <div class="grid grid-cols-1 gap-4 pt-4">
      <vs-table
          :sst="true"
          :data="periodLimitsList"
          :no-data-text="$t('nenhum-registro-encontrado')">
        <template slot="thead">
          <vs-th width="10%">{{ $t('ID') }}</vs-th>
          <vs-th width="20%">{{ $t('plan.start') }}</vs-th>
          <vs-th width="20%">{{ $t('plan.finish') }}</vs-th>
          <vs-th width="30%">{{ $t('plan.limits') }}</vs-th>
          <vs-th width="10%">{{ $t('actions') }}</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td width="10%">
              <div> {{ tr.id }} </div>
            </vs-td>
            <vs-td width="20%">
              <div> {{ tr.start_at_fmt }}</div>
            </vs-td>
            <vs-td width="20%">
              <div> {{_.get(tr, 'finish_at_fmt')}}</div>
            </vs-td>
            <vs-td width="30%">
              <div :key="indexLimit" v-for="(limit, indexLimit) in tr.limits">
                <span>{{$t(`plan.limit.${_.get(limit, 'type.name')}`)}}:&nbsp;{{_.get(limit, 'total')}}</span><br>
              </div>
            </vs-td>
            <vs-td width="15%">
              <feather-icon
                v-permission="'plans.edit'"
                icon="EditIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                @click="editData(data[indextr])"
                v-tooltip="{
                  content: $t('action.edit'),
                  delay: { show: 500, hide: 100 }
                }"/>
              <feather-icon
                v-permission="'plans.delete'"
                icon="Trash2Icon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
                @click="confirmDeleteInGrid(data[indextr])"
                v-tooltip="{
                  content: $t('action.delete'),
                  delay: { show: 500, hide: 100 }
                }"/>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>

import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import Moment from 'moment'

import PlanLimitTypePopup from './PlanLimitTypePopup.vue'
import PlanLimit from './PlanLimit.vue'

import PlansService from '@/services/api/PlansService'


export default {
  directives: {

  },
  components: {
    Datetime,
    PlanLimitTypePopup,
    PlanLimit
  },
  props: {
    plan_id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    model: {
      id: null,
      start_at: null,
      finish_at: null,
      limits: []
    },
    showPopupLimitType: false,
    periodLimitsList: [],
    limitsList: []
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.model.start_at)
              && !this.isEmpty(this.model.finish_at)
    },
  },
  beforeMount() {
    this.service = PlansService.build(this.$vs)
  },
  mounted() {
    this.loadPeriodLimitsList()
  },
  methods: {
    addLimitTypesList(list = null) {
      if (list && this._.isArray(list) && list.length > 0) {
        const entitys = []

        list.forEach(
          (entity) => entitys.push({
            id: null,
            type_id: entity.id,
            name: entity.name,
            target: entity.target
          })
        )
        this.addLimitTypes(entitys)
      }
    },
    addLimitTypes(list){
      list.forEach((limitType) => {
        const found = this.limitsList.find((entity)=>{
            return entity.type_id === limitType.type_id
        })

        if(!found){
          this.limitsList.push(limitType)
        }
      })
    },
    closePopup(show) {
      this.showPopupLimitType = show
    },
    isEdit() {
      return !_.isNil(this.model.id)
    },
    savePeriodLimits(){
      this.$vs.loading()

      this.model.limits = this.limitsList

      this.service.savePeriodLimits(this.plan_id, this.model).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.loadPeriodLimitsList()
          this.clearForm()
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    cancel() {
      this.$router.push('/plans')
    },
    deleteLimitInAdd(index, model){
      let newArray = this.limitsList.filter((element, indexLi) =>{
        return indexLi != index
      })
      this.limitsList = newArray
    },
    confirmDeleteInGrid(data){
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: ()=> {this.destroyData(data)}
      })
    },
    destroyData(entity){
      this.service.deletePeriodLimits(this.plan_id, entity.id).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.loadPeriodLimitsList()
          this.clearForm()
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )

    },
    editData(data){
      this.model.id = data.id

      this.model.start_at = Moment(data.start_at, 'YYYY-MM-DD').toDate().toISOString()
      this.model.finish_at = Moment(data.finish_at, 'YYYY-MM-DD').toDate().toISOString()
      //Limits
      this.limitsList = []
      data.limits.forEach((element) => {
        this.limitsList.push({
          id: element.id,
          total: element.total,
          type_id: element.type_id,
          name: element.type.name,
          target: element.type.target
        })
      })


    },
    clearForm(){
      this.limitsList = [] //Clear list trash
      this.model.start_at = null
      this.model.finish_at = null
      this.model.limits = []
    },
    loadPeriodLimitsList(){
      this.service.getPlanPeriods(this.plan_id).then(
        data => {
          this.periodLimitsList = data
        },
        error => {
          this.showErrors(this.$validator, error)
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    }
  }
}
</script>

<style>
.vs-list--header{
  display: inline !important;
}
</style>
