<template>
  <div>
    <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-2">
      <div class="flex w-full items-center w-5/6">
        {{$t(`plan.limit.${model.name}`)}}
      </div>
      <div class="flex w-full items-center w-5/6">
          {{model.target}}
      </div>
      <div class="flex w-full w-1/5">
        <vs-input
            lang="pt-BR"
            type="number"
            class="required"
            v-validate="'required|numeric'"
            :label="$t('plan.total')"
            v-model="model.total"/>
          <div class="items-center pt-5	">
            <vs-button
              color="danger"
              type="flat"
              icon-pack="feather"
              icon="icon-trash-2"
              class="float-center ml-4"
              @click="confirmDelete"
              v-tooltip="{
                content: $t('action.delete'),
                delay: { show: 500, hide: 100 }
              }"/>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    model: {
      default: null,
      type: Object
    },
    index: {
      default: 0,
      type: Number
    },
    showIndex: {
      default: true,
      type: Boolean
    },
    showButtonAdd: {
      default: false,
      type: Boolean
    },
    showButtonDelete: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    model:{
      id: null,
      name : '',
      total: ''
    }
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(_.get(this.model, 'total'))
    }
  },
  watch: {

  },
  beforeMount() {

  },
  mounted() {

  },

  methods: {
    confirmDelete(data){
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: ()=> {this.deleteModel()}
      })
    },
    deleteModel(){
      this.$emit('deleteModel', this.index, this.model)
    },
    totalChange() {
      let total = this.model.total
      try{
          Number.parseInt(total)
      }catch(err){
        this.model.total = 0
      }
      //
      if (score && Number.parseInt(score) < 0) {
        this.model.total = 0
      }
      if (score && Number.parseInt(score) > 100) {
        this.model.total = 100
      }

      console.log('total changing...:'+this.model.total)
    },
    validateKeyPresses(){
      let total = this.model.total
      if(!this._.isNumber(total)){
        this.model.total = ''
      }
    }
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style lang="scss" scopped>

</style>
