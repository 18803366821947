var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full gap-2" }, [
    _c("fieldset", { staticClass: "mt-4 border-faint-grey" }, [
      _c("legend", [_vm._v(_vm._s(_vm.$t("plan.stripe_integration")))]),
      _c(
        "div",
        { staticClass: "w-full p-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              id: "plan_integration_name",
              label: _vm.$t("plan.integration_name"),
            },
            model: {
              value: _vm.model.name,
              callback: function ($$v) {
                _vm.$set(_vm.model, "name", $$v)
              },
              expression: "model.name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-full p-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              id: "plan_integration_foreign_id",
              label: _vm.$t("plan.stripe_id"),
            },
            model: {
              value: _vm.model.foreign_id,
              callback: function ($$v) {
                _vm.$set(_vm.model, "foreign_id", $$v)
              },
              expression: "model.foreign_id",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-full p-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              id: "plan_integration_key",
              label: _vm.$t("plan.stripe_key"),
            },
            model: {
              value: _vm.model.key,
              callback: function ($$v) {
                _vm.$set(_vm.model, "key", $$v)
              },
              expression: "model.key",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-full p-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              id: "plan_integration_secret",
              label: _vm.$t("plan.stripe_secret"),
            },
            model: {
              value: _vm.model.secret,
              callback: function ($$v) {
                _vm.$set(_vm.model, "secret", $$v)
              },
              expression: "model.secret",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-full p-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              id: "plan_integration_subscription_id",
              label: _vm.$t("plan.subscription_id"),
            },
            model: {
              value: _vm.model.subscription_id,
              callback: function ($$v) {
                _vm.$set(_vm.model, "subscription_id", $$v)
              },
              expression: "model.subscription_id",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }