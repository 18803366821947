<template>
  <div>
    <vx-card class="mb-4">
      <vs-tabs :color="colorx">
        <vs-tab :label="$t('plan.title')">
          <div class="flex flex-col gap-4">
             <div class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
              <div class="flex">
                <vs-input
                  id="plan_name"
                  class="w-full required"
                  v-validate="'required|max:62'"
                  :label="$t('plan.name')"
                  v-model="model.name"/>
                <span class="text-danger text-sm" v-show="errors.has('plan_name')">{{errors.first('plan_name')}}</span>
              </div>
              <div class="flex">
                <vs-input
                  id="plan_internal_name"
                  class="w-full required"
                  v-validate="'required|max:62'"
                  :label="$t('plan.internal_name')"
                  v-model="model.internal_name"/>
                <span class="text-danger text-sm" v-show="errors.has('plan_internal_name')">{{errors.first('plan_internal_name')}}</span>
              </div>
             </div>
            <div class="w-full grid grid-cols-1 sm:grid-cols-4 md:grid-cols-4 gap-2">
              <div>
                <label for>{{ $t('plan.visible_signature') }}</label>
                  <vs-switch v-model="model.visible_signature"/>
                  <span class="text-danger text-sm" v-show="errors.has('visible_signature')">{{
                    errors.first('visible_signature')
                  }}</span>
              </div>
              <div class="flex">
                <vs-select
                  id="plan_currency_type"
                  class="w-full sm:w-1/2 flex-shrink required"
                  :label="$t('plan.currency_type')"
                  v-validate="'required'"
                  v-model="model.currency_type">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in currencyTypeList" />
                </vs-select>
                <span class="text-danger text-sm" v-show="errors.has('plan_currency_type')">{{errors.first('plan_currency_type')}}</span>
              </div>
              <div class="flex">
                <vs-input
                  id="plan_price_value"
                  lang="pt-BR"
                  class="required"
                  type="number"
                  v-validate="'required|numeric'"
                  :label="$t('plan.price_value')"
                  v-model="model.value"/>
                  <span class="text-danger text-sm" v-show="errors.has('plan_price_value')">{{errors.first('plan_price_value')}}</span>
              </div>
              <div class="flex">
                <vs-input
                  id="plan_duration"
                  type="number"
                  v-validate="'numeric|max:3'"
                   maxlength="3"
                   @keypress="isNumberInteger"
                  :label="$t('plan.duration_in_day')"
                  v-model="model.duration"/>
                  <span class="text-danger text-sm" v-show="errors.has('plan_duration')">{{errors.first('plan_duration')}}</span>
              </div>
            </div>
            <div class="w-full mt-2">
              <label class="vs-input--label">{{ $t('billing.billing_cycle') }}</label>
              <div>
                <vs-radio v-model="model.billing_cycle" vs-name="billing_cycle" vs-value="MO" class="mr-4">{{ $t('billing.cycle.MO') }}</vs-radio>
                <vs-radio v-model="model.billing_cycle" vs-name="billing_cycle" vs-value="AN" class="mr-4">{{ $t('billing.cycle.AN') }}</vs-radio>
                <vs-radio v-model="model.billing_cycle" vs-name="billing_cycle" vs-value="SA" class="mr-4">{{ $t('billing.cycle.SA') }}</vs-radio>
                <vs-radio v-model="model.billing_cycle" vs-name="billing_cycle" vs-value="QA" class="mr-4">{{ $t('billing.cycle.QA') }}</vs-radio>
                <vs-radio v-model="model.billing_cycle" vs-name="billing_cycle" vs-value="SU" class="mr-4">{{ $t('billing.cycle.SU') }}</vs-radio>
                <span class="text-danger text-sm" v-show="errors.has('billing_cycle')">{{errors.first('billing_cycle')}}</span>
              </div>
            </div>
            <div class="w-full">
              <vs-input
                id="plan_redirect_url"
                name="redirect_url"
                class="w-full"
                :label="$t('plan.redirect_url')"
                v-model="model.redirect_url"/>
              <span class="text-danger text-sm" v-show="errors.has('redirect_url')">{{errors.first('redirect_url')}}</span>
            </div>
            <div class="w-full">
              <vs-input
                id="plan_terms_url"
                name="terms_url"
                class="w-full"
                :label="$t('plan.terms_url')"
                v-model="model.terms_url"/>
              <span class="text-danger text-sm" v-show="errors.has('terms_url')">{{errors.first('terms_url')}}</span>
            </div>
            <div class="w-full">
              <label class="text-sm pl-1">{{ $t('plan.description') }}</label>
              <complex-editor
                v-bind:editor_data.sync="model.description"/>
              <span class="text-danger text-sm" v-show="errors.has('description')">{{errors.first('description')}}</span>
            </div>
            <div class="w-full flex gap-2 justify-end">
              <vs-button
                v-if="!isEdit()"
                :disabled="!validateForm"
                v-permission="'plans.create'"
                @click="createOrUpdate">
                {{ $t('action.save') }}
              </vs-button>
              <vs-button
                v-if="isEdit()"
                :disabled="!validateForm"
                v-permission="'plans.edit'"
                @click="createOrUpdate">
                {{ $t('action.save') }}
              </vs-button>

              <vs-button
                type="border"
                @click="cancel">
                {{ $t('common.back') }}
              </vs-button>
            </div>
          </div>
        </vs-tab>
        <vs-tab :label="$t('plan.limits')" v-if="isEdit()">
          <div class="vx-row mb-6" style="padding-top: 30px">
            <div class="vx-col w-full">
              <plan-period-limit :plan_id="id"></plan-period-limit>
            </div>
          </div>
        </vs-tab>
        <vs-tab :label="$t('plan.integration')" v-if="isEdit()">
          <div class="vx-row mb-6" style="padding-top: 30px">
            <div class="vx-col w-full">
              <div class="w-full">
                <PlanIntegration v-bind:model.sync="modelIntegration"/>
              </div>
              <div class="w-full flex gap-2 justify-end pt-4">
                <vs-button
                  v-if="isEdit()"
                  :disabled="!validateIntegrationForm"
                  v-permission="'plans.edit'"
                  @click="saveIntegrationForm">
                  {{ $t('action.save') }}
                </vs-button>
                <vs-button
                  type="border"
                  @click="cancel">
                  {{ $t('common.back') }}
                </vs-button>
              </div>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { isNumberInteger } from '@/util/NumberUtil'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import ComplexEditor from '../questionnaires/elements/ComplexEditor.vue'
import PlanIntegration from './PlanIntegration.vue'
import PlanPeriodLimit from './PlanPeriodLimit.vue'

import PlansService from '@/services/api/PlansService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
    ComplexEditor,
    PlanIntegration,
    PlanPeriodLimit
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    model: {
      id: null,
      name : '',
      internal_name : '',
      redirect_url: '',
      terms_url: '',
      description: '',
      currency_type: 'BRL',
      duration: '',
      value: null,
      visible_signature: false,
      billing_cycle: 'MO'
    },
    modelIntegration: {
      id: null,
      name: null,
      key: null,
      secret: null,
      foreign_id: null,
      subscription_id: null
    },
    currencyTypeList:  [
        {text: 'BRL', value: 'BRL'},
      ],
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.model.name)
    },
    validateIntegrationForm() {
      return true
    }
  },
  beforeMount() {
    this.service = PlansService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },

  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.populateModels(response)
        },
        error => {}
      ).finally(() => {
          this.$vs.loading.close()
        })
      //Integration
      this.service.getPlanIntegration(id).then(
        response => {
          this.populateIntegration(response)
        },
        error => {}
      ).finally(() => {
          this.$vs.loading.close()
        })
    },
    populateModels(data){
      this.model = {
        id: data.id,
        name: data.name,
        internal_name: data.internal_name,
        visible_signature: data.visible_signature,
        billing_cycle: data.billing_cycle,
        redirect_url: data.redirect_url,
        terms_url: data.terms_url,
        description: data.description,
        currency_type: _.get(data, 'currency_type'),
        duration: _.get(data, 'duration'),
        value: _.get(data, 'value')
      }
    },
    populateIntegration(data){
      this.modelIntegration.id = _.get(data, 'id')
      this.modelIntegration.name = _.get(data, 'name')
      this.modelIntegration.plan_id = _.get(data, 'plan_id')
      this.modelIntegration.key = _.get(data, 'key')
      this.modelIntegration.secret = _.get(data, 'secret')
      this.modelIntegration.foreign_id = _.get(data, 'foreign_id')
      this.modelIntegration.subscription_id = _.get(data, 'subscription_id')
    },
    createOrUpdate() {
      this.$vs.loading()

      const modelData = {
        id: this.model.id,
        name: this.model.name,
        internal_name: this.model.internal_name,
        visible_signature: this.model.visible_signature,
        redirect_url: this.model.redirect_url,
        terms_url: this.model.terms_url,
        description: this.model.description,
        currency_type: this.model.currency_type,
        value: this.model.value,
        duration: this.model.duration,
        billing_cycle: this.model.billing_cycle
      }

      this.service.createOrUpdate(modelData).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          if(this.model.id === null){
            this.$router.push(`/plans/${data.id}/edit`)
          }
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    saveIntegrationForm(){
      this.$vs.loading()

      this.service.updateIntegration(this.id,this.modelIntegration).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit() {
      return !_.isNil(this.id)
    },
    cancel() {
      this.$router.push('/plans')
    },
    isNumberInteger(val){
      return isNumberInteger(val)
    }
  }
}
</script>

<style>

</style>
