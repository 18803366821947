var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full gap-2" },
    [
      _c("div", { staticClass: "w-full pb-2" }, [
        _c("label", { staticClass: "block text-left font-medium" }, [
          _vm._v(_vm._s(_vm.$t("plan.period"))),
        ]),
        _c("hr", { staticClass: "border-faint-grey" }),
      ]),
      _c(
        "div",
        {
          staticClass:
            "w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2",
        },
        [
          _c("div", [
            _c("label", { attrs: { for: "plan_period_finish" } }, [
              _vm._v(_vm._s(_vm.$t("plan.start"))),
            ]),
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("datetime", {
                  staticClass: "flex-auto w-8/12 mr-1 tour-config-4",
                  attrs: {
                    id: "plan_period_start",
                    "input-class": "vs-inputx vs-input--input normal required",
                    phrases: { ok: "Ok", cancel: "Cancelar" },
                    type: "date",
                    format: "dd/MM/yyyy",
                    "value-zone": "America/Sao_Paulo",
                    placeholder: _vm.$t("data"),
                  },
                  model: {
                    value: _vm.model.start_at,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "start_at", $$v)
                    },
                    expression: "model.start_at",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("plan_period_start"),
                        expression: "errors.has('plan_period_start')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("plan_period_start")))]
                ),
              ],
              1
            ),
          ]),
          _c("div", [
            _c("label", { attrs: { for: "plan_period_finish" } }, [
              _vm._v(_vm._s(_vm.$t("plan.finish"))),
            ]),
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("datetime", {
                  staticClass: "flex-auto w-8/12 mr-1 tour-config-4",
                  attrs: {
                    id: "plan_period_finish",
                    "input-class": "vs-inputx vs-input--input normal required",
                    phrases: { ok: "Ok", cancel: "Cancelar" },
                    type: "date",
                    format: "dd/MM/yyyy",
                    "value-zone": "America/Sao_Paulo",
                    placeholder: _vm.$t("data"),
                  },
                  model: {
                    value: _vm.model.finish_at,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "finish_at", $$v)
                    },
                    expression: "model.finish_at",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("plan_period_finish"),
                        expression: "errors.has('plan_period_finish')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("plan_period_finish")))]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "w-full pb-2 pt-3" }, [
        _c("label", { staticClass: "block text-left font-medium" }, [
          _vm._v(_vm._s(_vm.$t("plan.limits"))),
        ]),
        _c("hr", { staticClass: "border-faint-grey" }),
      ]),
      _c(
        "div",
        { staticClass: "w-full gap-2 p-3" },
        [
          _c(
            "vs-button",
            {
              staticClass: "float-right mb-3",
              attrs: { icon: "add", type: "flat" },
              on: {
                click: function ($event) {
                  _vm.showPopupLimitType = true
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("adicionar")) + " ")]
          ),
          _c("vs-divider"),
          _c("plan-limit-type-popup", {
            attrs: { visible: _vm.showPopupLimitType },
            on: { selected: _vm.addLimitTypesList, close: _vm.closePopup },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-y-1" },
        _vm._l(_vm.limitsList, function (data, index) {
          return _c(
            "div",
            { key: "cIndex_" + index },
            [
              _c("plan-limit", {
                attrs: { model: data, index: index },
                on: { deleteModel: _vm.deleteLimitInAdd },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "w-full flex gap-2 justify-end pt-6" },
        [
          _c(
            "vs-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: "plans.edit",
                  expression: "'plans.edit'",
                },
              ],
              attrs: { disabled: !_vm.validateForm },
              on: { click: _vm.savePeriodLimits },
            },
            [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
          ),
          _c(
            "vs-button",
            { attrs: { type: "border" }, on: { click: _vm.cancel } },
            [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
          ),
        ],
        1
      ),
      _c("vs-divider", { staticClass: "mt-4" }, [
        _vm._v(_vm._s(_vm.$t("plan.period_limit_add"))),
      ]),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 pt-4" },
        [
          _c(
            "vs-table",
            {
              attrs: {
                sst: true,
                data: _vm.periodLimitsList,
                "no-data-text": _vm.$t("nenhum-registro-encontrado"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var data = ref.data
                    return _vm._l(data, function (tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c("vs-td", { attrs: { width: "10%" } }, [
                            _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                          ]),
                          _c("vs-td", { attrs: { width: "20%" } }, [
                            _c("div", [_vm._v(" " + _vm._s(tr.start_at_fmt))]),
                          ]),
                          _c("vs-td", { attrs: { width: "20%" } }, [
                            _c("div", [
                              _vm._v(
                                " " + _vm._s(_vm._.get(tr, "finish_at_fmt"))
                              ),
                            ]),
                          ]),
                          _c(
                            "vs-td",
                            { attrs: { width: "30%" } },
                            _vm._l(tr.limits, function (limit, indexLimit) {
                              return _c("div", { key: indexLimit }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "plan.limit." +
                                          _vm._.get(limit, "type.name")
                                      )
                                    ) +
                                      ": " +
                                      _vm._s(_vm._.get(limit, "total"))
                                  ),
                                ]),
                                _c("br"),
                              ])
                            }),
                            0
                          ),
                          _c(
                            "vs-td",
                            { attrs: { width: "15%" } },
                            [
                              _c("feather-icon", {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "plans.edit",
                                    expression: "'plans.edit'",
                                  },
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      content: _vm.$t("action.edit"),
                                      delay: { show: 500, hide: 100 },
                                    },
                                    expression:
                                      "{\n                content: $t('action.edit'),\n                delay: { show: 500, hide: 100 }\n              }",
                                  },
                                ],
                                attrs: {
                                  icon: "EditIcon",
                                  svgClasses:
                                    "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editData(data[indextr])
                                  },
                                },
                              }),
                              _c("feather-icon", {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "plans.delete",
                                    expression: "'plans.delete'",
                                  },
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      content: _vm.$t("action.delete"),
                                      delay: { show: 500, hide: 100 },
                                    },
                                    expression:
                                      "{\n                content: $t('action.delete'),\n                delay: { show: 500, hide: 100 }\n              }",
                                  },
                                ],
                                attrs: {
                                  icon: "Trash2Icon",
                                  svgClasses:
                                    "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmDeleteInGrid(
                                      data[indextr]
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { attrs: { width: "10%" } }, [
                    _vm._v(_vm._s(_vm.$t("ID"))),
                  ]),
                  _c("vs-th", { attrs: { width: "20%" } }, [
                    _vm._v(_vm._s(_vm.$t("plan.start"))),
                  ]),
                  _c("vs-th", { attrs: { width: "20%" } }, [
                    _vm._v(_vm._s(_vm.$t("plan.finish"))),
                  ]),
                  _c("vs-th", { attrs: { width: "30%" } }, [
                    _vm._v(_vm._s(_vm.$t("plan.limits"))),
                  ]),
                  _c("vs-th", { attrs: { width: "10%" } }, [
                    _vm._v(_vm._s(_vm.$t("actions"))),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }