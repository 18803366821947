var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-2" },
      [
        _c("div", { staticClass: "flex w-full items-center w-5/6" }, [
          _vm._v(" " + _vm._s(_vm.$t("plan.limit." + _vm.model.name)) + " "),
        ]),
        _c("div", { staticClass: "flex w-full items-center w-5/6" }, [
          _vm._v(" " + _vm._s(_vm.model.target) + " "),
        ]),
        _c(
          "div",
          { staticClass: "flex w-full w-1/5" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|numeric",
                  expression: "'required|numeric'",
                },
              ],
              staticClass: "required",
              attrs: {
                lang: "pt-BR",
                type: "number",
                label: _vm.$t("plan.total"),
              },
              model: {
                value: _vm.model.total,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "total", $$v)
                },
                expression: "model.total",
              },
            }),
            _c(
              "div",
              { staticClass: "items-center pt-5\t" },
              [
                _c("vs-button", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.$t("action.delete"),
                        delay: { show: 500, hide: 100 },
                      },
                      expression:
                        "{\n              content: $t('action.delete'),\n              delay: { show: 500, hide: 100 }\n            }",
                    },
                  ],
                  staticClass: "float-center ml-4",
                  attrs: {
                    color: "danger",
                    type: "flat",
                    "icon-pack": "feather",
                    icon: "icon-trash-2",
                  },
                  on: { click: _vm.confirmDelete },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }